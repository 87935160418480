$(document).ready(function () {
    // Home Page and FAQs
    $("#faqs .card-body").children().addClass("product-fp3");
    $("#faqs .col-10.col-sm-10 p").addClass("no-d");
    $("#wheretobuy .col-md-12").children("h1,h2,h3,h4,h5,h6").addClass("head-1");
    $("#wheretobuy .col-md-12").children("ol,ul,p").addClass("product-fp");
    // Privacy Policy
    $(".privacy-statement").children("p").addClass("f-article-body");
    $(".privacy-statement").children("ol,ul").addClass("terms-color");
    $(".privacy-statement").children("h1,h2,h3,h4,h5,h6").addClass("terms-li");
    // Article Details
    $(".article-type-data > h1.f-article-head2").siblings().addClass("f-article-body");
    if (window.location.hash != '') {
        $(window.location.hash).find(".faq-ans").addClass('show');
        $(window.location.hash).find(".btn-link").find(".fa").removeClass("fa-plus").addClass("fa-minus");

        $(this).on("click", function () {
            $(window.location.hash).find(".faq-ans").find(".faq-ans").removeClass('show');
        });
    }
});
$(document).on("keyup blur", "#globalSearch input, #exampleFormControlInput1", function (e) {
    var keyword = e.target.value;
    if (e.keyCode == 13 && keyword != "") {
        window.location = url + "search/" + keyword.replace(/\s/g, "-");
    }
    this.value = this.value.replace(/[^a-zA-Z0-9-'\u00f1\u00d1\n ._,-\/]/g, '');
    $(this).attr("accept", "/[^a-zA-Z0-9-'\u00f1\u00d1\n ._,-\/]/g,''");
});
$(document).on("submit", "#globalSearch", function (e) {
    e.preventDefault();
    var keyword = e.target.value;
    if (keyword != "") {
        window.location = url + "search/" + document.forms[0].querySelector("input").value.replace(/\s/g, "-");
    }
});
$(document).on("click", "#btn_search_result", function (e) {
    var keyword = document.getElementById('exampleFormControlInput1').value;
    if (keyword != "") {
        window.location = url + "search/" + keyword.replace(/\s/g, "-");
    }
});
$(document).on("click", "#globalSearch button", function (e) {
    var keyword = $("#globalSearch input").val();
    if (keyword != "") {
        window.location = url + "search/" + keyword.replace(/\s/g, "-");
    }
});
$(window).on("load resize", function () {
    var resizeTimer;

    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
        // Run code here, resizing has "stopped"
        articleHomeEqualHeight();
    }, 250);
});

function articleHomeEqualHeight() {
    var maxHeightimg = 0;
    var maxHeighttitle = 0;
    var maxHeighttext = 0;
    $('#articles .card img, #articles .card .card-title, #articles .card .card-text').css('height', '100%');
    $('#articles .card img').css('width', '100%');
    $('#articles .card img').css('max-width', '100%');
    $('#articles .card img').each(function (index) {
        var height = $(this).height();
        if (maxHeightimg <= height) {
            maxHeightimg = height;
        }
    });
    $('#articles .card .card-title').each(function (index) {
        var height = $(this).height();
        if (maxHeighttitle <= height) {
            maxHeighttitle = height + 3;
        }
    });
    $('#articles .card .card-text').each(function (index) {
        var height = $(this).height();
        if (maxHeighttext <= height) {
            maxHeighttext = height;
        }
    });
    if (this.matchMedia("(min-width: 360px)").matches) {
        $('#articles .card img').css('height', maxHeightimg);
        $('#articles .card img').css('width', '100%');
        $('#articles .card img').css('max-width', 'unset');
        $('#articles .card .card-title').css('height', maxHeighttitle);
        $('#articles .card .card-text').css('height', maxHeighttext);
    }
}