$(document).ready(function(){
	runStart();

	$('.nav-link').click(function(){
		$('.navbar-collapse').collapse('hide');
	});
});

$(window).resize(function(){
	runStart();
});

function runStart(){
	var windowHeight = window.innerHeight;
	var headerHeight = $(".gp-header").innerHeight();
	var footerHeight = $(".gp-footer").innerHeight();
	var adjsHeight = windowHeight - footerHeight;
	var adjsHeightContact =  adjsHeight - headerHeight;
	var kvminHeight = $(".gp-section .gp-container").innerHeight();





	$(".gp-headingadjust").css("padding-top", headerHeight);

	$(".gp-headingadjust").css("margin-bottom", headerHeight * -1);

	$(".gp-endersection").css("min-height", adjsHeight);

	$(".gp-endersection").css("margin-bottom", 0);

	$(".gp-contactheight").css("min-height", adjsHeightContact);

	$(".gp-hr").css("margin-top", headerHeight);

	$(".gp-hr").css("margin-bottom", headerHeight * -1);


    var width = $('.g-recaptcha').parent().width();
    if (width < 302) {
		var scale = width / 302;
		$('.g-recaptcha').css('transform', 'scale(' + scale + ')');
		$('.g-recaptcha').css('-webkit-transform', 'scale(' + scale + ')');
		$('.g-recaptcha').css('transform-origin', '0 0');
		$('.g-recaptcha').css('-webkit-transform-origin', '0 0');
	}
}

$(".gp-search").focusin(function() {
	$(this).addClass('focused');
	$(this).attr("placeholder", "What are you looking for?");
});
$(".gp-search").focusout(function() {
	$(this).removeClass('focused');
	$(this).attr("placeholder", "SEARCH");
});


var sections = $('.gp-section'),
nav = $('nav'),
nav_height = nav.outerHeight();

$(window).on('scroll', function () {
  var cur_pos = $(this).scrollTop();

  sections.each(function() {
	var top = $(this).offset().top - nav_height,
		bottom = top + $(this).outerHeight();

	if (cur_pos >= top && cur_pos <= bottom) {
		$('nav a').parent().removeClass('active');
		$('nav a[href*="'+$(this).attr('id')+'"]').parent().addClass('active');
	}
  });
});
